import { fromJS, Map } from "immutable";
import { Reducer } from "redux";
import { leaderboardActionTypes } from "./constants";
import { ILeaderboard } from "@common/types/leaderboard.types";

interface LeaderboardState {
  isLoading: boolean;
  error: null | any;
  leaderboard: ILeaderboard | null;
}

const initialState: LeaderboardState = {
  isLoading: false,
  error: null,
  leaderboard: null,
};

// @ts-ignore
const leaderboardReducer: Reducer<Map<string, any>, leaderboardActionTypes> = (
  state = fromJS(initialState),
  { type, payload },
) => {
  switch (type) {
    case leaderboardActionTypes.GET_LEADERBOARD:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case leaderboardActionTypes.GET_LEADERBOARD_SUCCESS:
      return state.merge({
        isLoading: false,
        leaderboardInfo: payload,
      });

    case leaderboardActionTypes.GET_LEADERBOARD_FAIL:
      return state.merge({
        isLoading: false,
        error: payload
      });

    case leaderboardActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
       });

    default:
      return state;
  }
};

export default leaderboardReducer;
