import React from 'react';
import styled from 'styled-components';
import CoinAnimation from '@components/Attoms/CoinAnimation';
import LoaderWrapper from '@components/Attoms/LoaderWrapper';

export const CoinContainer = styled.div`
  width: 185px;
  height: 185px;
`;

interface LoaderProps {
  visible: boolean;
}

const CoinLoader: React.FC<LoaderProps> = ({ visible }) => (
  <LoaderWrapper visible={visible}>
    <CoinContainer>
      <CoinAnimation />
    </CoinContainer>
  </LoaderWrapper>
);

export default CoinLoader;
